const Bugsnag = require('@bugsnag/js');
const BugsnagPluginReact = require('@bugsnag/plugin-react');
const { AuthErrors } = require('@/constants/users');

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.APP_ENV,
  enabledReleaseStages: ['production', 'staging', 'development'],
  onError: function (event) {
    if (event.originalError.message === AuthErrors.loginRequired) return false;
  },
});

module.exports = Bugsnag;
