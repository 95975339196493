export enum UserRole {
  contact = 'Contact',
  coordinator = 'Coordinator',
  facilitator = 'Facilitator',
  apprenticeFacilitator = 'Apprentice facilitator',
  healingGroupLeader = 'Healing group leader',
  healingGroupFacilitator = 'Healing group facilitator',
  listeningGroupFacilitator = 'Listening group leader',
  trainingFacilitator = 'Training leader',
  masterFacilitatorInTraining = 'Master facilitator in training',
  masterFacilitator = 'Master facilitator',
}

export const LastNameNoName = 'NoName';

export enum AuthErrors {
  loginRequired = 'Login required',
}
