import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

export const usePath = () => {
  const standardPathname = usePathname();

  const [pathname, setPathname] = useState(standardPathname);

  useEffect(() => {
    if (standardPathname !== '/' && standardPathname[standardPathname.length - 1] === '/')
      setPathname(standardPathname.slice(0, standardPathname.length - 1));
    else if (standardPathname.split('.')[1] === 'html') setPathname(standardPathname.split('.')[0]);
    else if (pathname !== standardPathname) setPathname(standardPathname);
  }, [standardPathname]);

  return pathname;
};
