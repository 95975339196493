import { Spin } from "antd/lib";
import React from "react";

const PageLoader: React.FC = () => {
  return (
    <div className="spinner-wrapper">
      <Spin size="large" />
    </div>
  );
};

export default PageLoader;
