import { User } from '@auth0/auth0-react';
import { Dispatch, SetStateAction } from 'react';
import { useProfileFetchers } from '@/hooks/useProfileFetchers';
import { useAuth } from '@/context/AuthContext';
import { Certification } from '@/types/currentUser';

export const useUserData = () => {
  const { fetchProfileData } = useProfileFetchers();
  const { certifications } = useAuth();

  const checkUserOnboardingIsCompleted = async (user: User, setIsLoading: Dispatch<SetStateAction<boolean>>) => {
    let isCompleted = user.app_onboarding_completed;

    if (isCompleted) return isCompleted;

    const { data } = await fetchProfileData(setIsLoading);

    return data[0]?.onboarding_completed;
  };

  const checkUserIsFacilitator = (certificationsThatWillBeSet?: Certification[]) => {
    return certifications.length || certificationsThatWillBeSet?.length;
  };

  const checkUserIsJustAContact = (user: User, certificationsThatWillBeSet?: Certification[]) => {
    return !checkUserIsFacilitator(certificationsThatWillBeSet) && !user.app_coordinator;
  };

  const checkUserIsAnApprenticeFacilitator = () => {
    return certifications.some((certificate) => {
      return certificate.level.level === 1;
    });
  };

  const checkUserIsATrainingOrMasterFacilitator = () => {
    return certifications.some((certificate) => {
      return certificate.level.level >= 3;
    });
  };

  const checkUserIsAMasterFacilitator = () => {
    return certifications.some((certificate) => {
      return certificate.level.level === 5;
    });
  };

  return {
    checkUserOnboardingIsCompleted,
    checkUserIsFacilitator,
    checkUserIsJustAContact,
    checkUserIsAnApprenticeFacilitator,
    checkUserIsATrainingOrMasterFacilitator,
    checkUserIsAMasterFacilitator,
  };
};
