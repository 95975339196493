import { apiProfileUrl } from '@/utils/apiUrls';
import { ProfileData, ProfileDataResponse } from '@/types/currentUser';
import { Dispatch, SetStateAction } from 'react';
import { useCommonFetchers } from '@/hooks/useCommonFetchers';

export const useProfileFetchers = () => {
  const { fetcher } = useCommonFetchers();

  const fetchProfileData = async (setIsLoading: Dispatch<SetStateAction<boolean>>): Promise<ProfileDataResponse> => {
    return fetcher(apiProfileUrl(), setIsLoading);
  };

  const updateProfileData = async (payload: Partial<ProfileData>, setIsLoading: Dispatch<SetStateAction<boolean>>) => {
    return fetcher(apiProfileUrl(), setIsLoading, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
  };

  return { fetchProfileData, updateProfileData };
};
