"use client";

import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useTransition,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useRouter } from "next/navigation";
import {
  createEventUrl,
  DASHBOARD_BASE_PATH,
  dashboardUrl,
  favoritesProgramsUrl,
  findUsersDashboardUrl,
  healingBulkUploadUrl,
  materialsLibraryUrl,
  myEventsUrl,
  myHealingReportingUrl,
  myReportingUrl,
  ongoingMyEventsUrl,
  personalInfoUrl,
  PUBLIC_EVENTS_BASE_PATH,
  publicEventsUrl,
  TRAINING_BASE_PATH,
} from "@/utils/appUrls";
import { useTranslation } from "react-i18next";
import { analytics } from "@/constants/common";
import { useCommonFetchers } from "@/hooks/useCommonFetchers";
import { useUserData } from "@/hooks/useUserData";
import { usePath } from "@/hooks/usePath";
import { App } from "antd";
import Bugsnag from "@/bugsnag";
import { Certification } from "@/types/currentUser";
import PageLoader from "@/src/components/Common/PageLoader";

interface AuthContextType {
  meDataLoading: boolean;
  meDataError: boolean;
  onboardingIsCompleted: boolean;
  setOnboardingIsCompleted: Dispatch<SetStateAction<boolean>>;
  avatar: string;
  setAvatar: Dispatch<SetStateAction<string>>;
  preferredLanguageId: string;
  setPreferredLanguageId: Dispatch<SetStateAction<string>>;
  referralToken: string | undefined;
  firstName: string | undefined;
  certifications: Certification[];
  setFirstName: Dispatch<SetStateAction<string | undefined>>;
  lastName: string | undefined;
  setLastName: Dispatch<SetStateAction<string | undefined>>;
  userIsRetoolingFacilitator: boolean;
  userIsAdmin: boolean;
  setExpiredSessionError: Dispatch<SetStateAction<boolean>>;
  userIsConfidential: boolean;
}

const AuthContext = createContext<AuthContextType>({
  meDataError: false,
  meDataLoading: false,
  onboardingIsCompleted: false,
  setOnboardingIsCompleted: () => false,
  avatar: "",
  setAvatar: () => "",
  preferredLanguageId: "",
  setPreferredLanguageId: () => "",
  referralToken: "",
  firstName: "",
  setFirstName: () => "",
  lastName: "",
  setLastName: () => "",
  certifications: [],
  userIsRetoolingFacilitator: false,
  userIsAdmin: false,
  setExpiredSessionError: () => false,
  userIsConfidential: false,
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { user, isAuthenticated, isLoading, error: auth0Error, loginWithRedirect } = useAuth0();
  const router = useRouter();
  const pathname = usePath();
  const { t } = useTranslation();
  const { getMe } = useCommonFetchers();
  const { checkUserIsJustAContact, checkUserOnboardingIsCompleted } = useUserData();
  const { message } = App.useApp();
  const [isPending, startTransition] = useTransition();
  const [checkUserIsAuthenticatedLoading, setCheckUserIsAuthenticatedLoading] = useState(true);
  const [onboardingIsCompleted, setOnboardingIsCompleted] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [preferredLanguageId, setPreferredLanguageId] = useState("");
  const [meDataLoading, setMeDataLoading] = useState(true);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [referralToken, setReferralToken] = useState<string>();
  const [shouldFinishLoadingAfterRedirect, setShouldFinishLoadingAfterRedirect] = useState(false);
  const [certifications, setCertifications] = useState([]);
  const [userIsRetoolingFacilitator, setUserIsRetoolingFacilitator] = useState(false);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [meDataError, setMeDataError] = useState(false);
  const [expiredSessionError, setExpiredSessionError] = useState(false);
  const [userIsConfidential, setUserIsConfidential] = useState(false);

  const navigateGuest = async () => {
    if (pathname === "/") {
      startTransition(() => router.push(publicEventsUrl()));
      setShouldFinishLoadingAfterRedirect(true);
    } else if (
      pathname.startsWith(DASHBOARD_BASE_PATH) ||
      pathname.startsWith(TRAINING_BASE_PATH) ||
      pathname === materialsLibraryUrl()
    ) {
      await loginWithRedirect({
        appState: { returnTo: window.location.href },
      });
    } else {
      setCheckUserIsAuthenticatedLoading(false);
    }
  };

  const navigateUser = async () => {
    if (!user) {
      const error = t("common.messages.unavailablePage");
      message.error(error);
      throw new Error(error);
    }

    const userIsJustContact = checkUserIsJustAContact(user, certifications);
    let route: string | undefined;
    let isOnboardingCompleted;

    if (!onboardingIsCompleted) {
      isOnboardingCompleted = await checkUserOnboardingIsCompleted(user, setCheckUserIsAuthenticatedLoading);
    } else {
      isOnboardingCompleted = true;
    }

    setOnboardingIsCompleted(isOnboardingCompleted);

    if (
      !isOnboardingCompleted &&
      (pathname === "/" ||
        (pathname.startsWith(DASHBOARD_BASE_PATH) && pathname !== personalInfoUrl()) ||
        pathname.startsWith(TRAINING_BASE_PATH) ||
        pathname.startsWith(PUBLIC_EVENTS_BASE_PATH) ||
        pathname === materialsLibraryUrl())
    ) {
      route = personalInfoUrl();
    } else if (
      pathname === "/" ||
      (userIsJustContact &&
        pathname.startsWith(DASHBOARD_BASE_PATH) &&
        pathname !== dashboardUrl() &&
        pathname !== personalInfoUrl() &&
        pathname !== favoritesProgramsUrl()) ||
      (!user.app_coordinator && pathname === healingBulkUploadUrl()) ||
      (!userIsAdmin && pathname === findUsersDashboardUrl())
    ) {
      route = dashboardUrl();
    } else if (userIsRetoolingFacilitator && (pathname === createEventUrl() || pathname === myEventsUrl("/edit"))) {
      route = ongoingMyEventsUrl();
    } else if (userIsRetoolingFacilitator && pathname === myReportingUrl("/create")) {
      route = myHealingReportingUrl(0);
    }

    if (route) {
      startTransition(() => router.push(route as string));
      setShouldFinishLoadingAfterRedirect(true);
    } else {
      setCheckUserIsAuthenticatedLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated || !user) {
      if (meDataLoading) setMeDataLoading(false);

      return;
    }

    if (user.user_first_name && firstName !== user.user_first_name) setFirstName(user.user_first_name);
    if (user.user_last_name && lastName !== user.user_last_name) setLastName(user.user_last_name);

    const handleAvatarLoad = (avatarUrl: string) => {
      const img = new Image();
      img.src = avatarUrl;

      img.onload = () => {
        setAvatar(avatarUrl);
      };

      img.onerror = () => {
        setAvatar(user?.picture || "");
      };
    };

    const fetchUserData = async () => {
      if (
        avatar ||
        preferredLanguageId ||
        certifications.length ||
        userIsRetoolingFacilitator ||
        userIsAdmin ||
        userIsConfidential
      )
        return;

      if (!meDataLoading) setMeDataLoading(true);

      let me: any;

      try {
        me = await getMe(setMeDataLoading);
      } catch (error) {
        setMeDataError(true);
        throw error;
      }

      const userData = me.data;

      if (userData.avatar) {
        handleAvatarLoad(userData.avatar);
      } else {
        setAvatar(user?.picture || "");
      }

      if (userData.language_id) {
        setPreferredLanguageId(userData.language_id);
      }
      if (userData.certifications) {
        setCertifications(userData.certifications);
      }
      if (userData.referral_token) {
        setReferralToken(userData.referral_token);
      }
      if (userData.retooling) {
        setUserIsRetoolingFacilitator(true);
      }
      if (userData.admin) {
        setUserIsAdmin(true);
      }
      if (userData.confidential) {
        setUserIsConfidential(true);
      }

      setMeDataLoading(false);
    };

    fetchUserData();
  }, [
    isLoading,
    isAuthenticated,
    avatar,
    preferredLanguageId,
    certifications.length,
    userIsRetoolingFacilitator,
    userIsAdmin,
    userIsConfidential,
    user?.user_first_name,
    user?.user_last_name,
  ]);

  useEffect(() => {
    if (expiredSessionError || auth0Error) return;

    if (isLoading || meDataLoading) {
      if (!checkUserIsAuthenticatedLoading) setCheckUserIsAuthenticatedLoading(true);
      return;
    }

    const checkUserIsAuthenticatedAndNavigate = async () => {
      if (isAuthenticated) {
        await navigateUser();
      } else {
        await navigateGuest();
      }
    };

    checkUserIsAuthenticatedAndNavigate();
  }, [isLoading, pathname, isAuthenticated, meDataLoading]);

  useEffect(() => {
    if (!auth0Error) return;

    if (!checkUserIsAuthenticatedLoading) setCheckUserIsAuthenticatedLoading(true);

    message.warning(t("common.messages.somethingWentWrong"));
    loginWithRedirect();

    throw auth0Error;
  }, [auth0Error]);

  useEffect(() => {
    if (!auth0Error) return;

    if (!checkUserIsAuthenticatedLoading) setCheckUserIsAuthenticatedLoading(true);

    message.warning(t("common.messages.somethingWentWrong"));
    loginWithRedirect();

    throw auth0Error;
  }, [auth0Error]);

  useEffect(() => {
    if (!expiredSessionError) return;

    message.error(t("common.messages.sessionIsExpired"));
    loginWithRedirect();
  }, [expiredSessionError]);

  useEffect(() => {
    if (!user) return;

    analytics.identify(user.app_teamdeskId, {
      name: user.name,
      email: user.email,
    });

    // @ts-ignore
    Bugsnag.setUser(user.sub);
  }, [user?.name, user?.email, user?.app_teamdeskId]);

  useEffect(() => {
    if (isPending || !shouldFinishLoadingAfterRedirect) return;

    setCheckUserIsAuthenticatedLoading(false);
  }, [isPending]);

  return checkUserIsAuthenticatedLoading || meDataLoading || expiredSessionError ? (
    <PageLoader />
  ) : (
    <AuthContext.Provider
      value={{
        meDataError,
        onboardingIsCompleted,
        setOnboardingIsCompleted,
        avatar,
        setAvatar,
        preferredLanguageId,
        setPreferredLanguageId,
        certifications,
        referralToken,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        userIsRetoolingFacilitator,
        userIsAdmin,
        meDataLoading,
        setExpiredSessionError,
        userIsConfidential,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
