import { buildUrlWithParams } from '@/utils/helpers';
import { EventsActivityTypes, EventsTimePeriods } from '@/constants/events';
import { SearchParams as SearchParamsType } from '@/types/common';
import { SearchParams } from '@/constants/common';

const BASE_API_PATH = process.env.BACKEND_API_ENDPOINT!;

// Banners

export const apiGenericBanner = () => buildUrlWithParams(BASE_API_PATH, '/banners');
export const apiMobileBanner = () => buildUrlWithParams(BASE_API_PATH, '/mobile-banner');

// Dashboard

const DASHBOARD_BASE_PATH = `${BASE_API_PATH}/dashboard`;
export const apiDashboard = () => buildUrlWithParams(DASHBOARD_BASE_PATH, '');

// My events

const MY_EVENTS_BASE_PATH = `${BASE_API_PATH}/my-events`;
const apiMyEvents = (nestedRoute: string, params?: SearchParamsType, routePostfix?: string) =>
  buildUrlWithParams(`${MY_EVENTS_BASE_PATH}${routePostfix || ''}`, nestedRoute, params);

export const apiMyEventsList = (
  offset: string,
  limit: string,
  timePeriod?: EventsTimePeriods,
  activityType?: string,
  filter?: SearchParamsType,
) =>
  apiMyEvents('', {
    [SearchParams.offset]: offset,
    [SearchParams.limit]: limit,
    [SearchParams.filterActivityType]: activityType,
    [SearchParams.filterTimePeriod]: timePeriod,
    ...filter,
  });
export const apiMyEventsNextPageCount = (
  offset: string,
  limit: string,
  timePeriod?: EventsTimePeriods,
  activityType?: string,
  filter?: SearchParamsType,
) =>
  apiMyEvents(
    '',
    {
      [SearchParams.offset]: offset,
      [SearchParams.limit]: limit,
      [SearchParams.filterActivityType]: activityType,
      [SearchParams.filterTimePeriod]: timePeriod,
      ...filter,
    },
    '-next-page-count',
  );
export const apiMyEventDetails = (id: string) => apiMyEvents(`/${id}`);

// Public events

const PUBLIC_EVENTS_BASE_PATH = `${BASE_API_PATH}/events`;
const apiPublicEvents = (nestedRoute: string, params?: SearchParamsType, routePostfix?: string) =>
  buildUrlWithParams(`${PUBLIC_EVENTS_BASE_PATH}${routePostfix ? `-${routePostfix}` : ''}`, nestedRoute, params);

export const apiPublicEventsList = (offset: string, limit: string, filter?: SearchParamsType) =>
  apiPublicEvents('', { [SearchParams.offset]: offset, [SearchParams.limit]: limit, ...filter });
export const apiPublicEventsNextPageCount = (offset: string, limit: string, filter?: SearchParamsType) =>
  apiPublicEvents('', { [SearchParams.offset]: offset, [SearchParams.limit]: limit, ...filter }, 'next-page-count');
export const apiPublicEventDetails = (id: string) => apiPublicEvents(`/${id}`);

// Event

const EVENT_BASE_PATH = `${BASE_API_PATH}/event`;
const apiEventUrl = (nestedRoute: string, params?: SearchParamsType) =>
  buildUrlWithParams(EVENT_BASE_PATH, nestedRoute, params);

export const apiCreateEvent = (typeId: string, isReport: boolean) =>
  apiEventUrl(isReport ? `/type/${typeId}/report` : `/type/${typeId}`);
export const apiEditEvent = (id: string, isReport: boolean) => apiEventUrl(isReport ? `/${id}/report` : `/${id}`);
export const apiDeleteEvent = (id: string) => apiEventUrl(`/${id}`);
export const apiEditEventAttachment = (id: string) => apiEventUrl(`/${id}/attachment`);
export const apiGenerateUrlForUploadingFileForReport = (id: string) => apiEventUrl(`/${id}/report/file`);
export const apiRegisterToEventAsUser = (id: string) => apiEventUrl(`/${id}/participant/user`);
export const apiRegisterToEventAsGuest = (id: string) => apiEventUrl(`/${id}/participant/guest`);
export const apiEventParticipants = (id: string) => apiEventUrl(`/${id}/participants`);
export const apiValidateEventParticipants = (id: string) => apiEventUrl(`/${id}/validate-participants`);
export const apiAddEventParticipantsByEmails = (id: string) => apiEventUrl(`/${id}/save-participants`);
export const apiAddEventParticipantsByFullNames = (id: string) => apiEventUrl(`/${id}/save-participants-by-name`);
export const apiSendInviteToEvent = (eventId: string, participantId: string) =>
  apiEventUrl(`/${eventId}/participants/${participantId}/invite`);
export const apiDeleteEventParticipant = (eventId: string, participantId: string) =>
  apiEventUrl(`/${eventId}/participants/${participantId}`);
export const apiCreateReport = (id: string, type: EventsActivityTypes) =>
  apiEventUrl(`/${id}/report/${type.toLowerCase()}`);
export const apiCreateEquippingReportWithConfidentialParticipants = (id: string) =>
  apiEventUrl(`/${id}/report/confidential-equipping`);
export const apiEventPeople = (id: string, searchQuery?: string) =>
  apiEventUrl(`/${id}/people`, { [SearchParams.filterSearch]: searchQuery });

// Profile

const PROFILE_BASE_PATH = `${BASE_API_PATH}/profile`;
export const apiProfileUrl = (nestedRoute?: string, params?: SearchParamsType) =>
  buildUrlWithParams(PROFILE_BASE_PATH, nestedRoute || '', params);

export const apiProfileListsData = () => apiProfileUrl('/lists-data');

// Materials

const MATERIALS_BASE_PATH = `${BASE_API_PATH}/material`;
const apiMaterial = (nestedRoute: string, params?: SearchParamsType, routePostfix?: string) =>
  buildUrlWithParams(`${MATERIALS_BASE_PATH}${routePostfix ? `-${routePostfix}` : ''}`, nestedRoute, params);

export const apiMaterialFiles = (materialId: string) => apiMaterial(`/${materialId}`, {}, 'files');
export const apiMaterialFilesV2 = () =>
  apiMaterial('', { [SearchParams.filterMaterialFileContentIsFavorite]: 'true' }, 'files-v2');
export const apiMaterialFolders = (materialId?: string, filter?: SearchParamsType) =>
  apiMaterial(materialId ? `/${materialId}` : '', filter, 'folders');
export const apiMaterialFolderFiles = (filter: SearchParamsType) => apiMaterial('', filter, 'folder-files');
export const apiMaterialsFilters = () => buildUrlWithParams(BASE_API_PATH, '/filters');

// Organizations

const ORGANIZATIONS_BASE_PATH = `${BASE_API_PATH}/organizations`;
export const apiOrganizations = (nestedRoute = '', params?: SearchParamsType) =>
  buildUrlWithParams(ORGANIZATIONS_BASE_PATH, nestedRoute, params);

export const apiCreateOrganizationListsData = () => apiOrganizations('/lists-data');
export const apiOrganizationsExpertiseAreas = () => apiOrganizations('/expertise-areas');
export const apiOrganizationsTypes = () => apiOrganizations('/types');
export const apiInternationalOrganizations = (searchQuery?: string) =>
  apiOrganizations('/international', { [SearchParams.filterSearch]: searchQuery });

// Participant form

const PARTICIPANT_FORM_BASE_PATH = `${BASE_API_PATH}/healing/participant`;
const apiHealingParticipantForm = (nestedRoute: string, routePostfix?: string) =>
  buildUrlWithParams(`${PARTICIPANT_FORM_BASE_PATH}${routePostfix ? `-${routePostfix}` : ''}`, nestedRoute);

export const apiHealingParticipant = () => apiHealingParticipantForm('');
export const apiHealingParticipantFeedback = () => apiHealingParticipantForm('', 'feedback');

// S3

const PRESIGNED_URL_BASE_PATH = `${BASE_API_PATH}/s3/presigned-url/`;
const apiPresignedUrl = (nestedRoute: string) => buildUrlWithParams(PRESIGNED_URL_BASE_PATH, nestedRoute);

export const apiHealingBulkUploadPresignedUrl = () => apiPresignedUrl('');
export const apiPresignedAvatarUrl = () => apiPresignedUrl('avatar');

// Common

export const apiCountries = () => buildUrlWithParams(BASE_API_PATH, '/countries');
export const apiConfidentialCountries = () => buildUrlWithParams(BASE_API_PATH, '/confidential-countries');
export const apiDenominations = () => buildUrlWithParams(BASE_API_PATH, '/denominations');
export const apiActivityTypes = () => buildUrlWithParams(BASE_API_PATH, '/activity-types');
export const apiActivityLanguages = () => buildUrlWithParams(BASE_API_PATH, '/activity-languages');
export const apiPeople = (searchQuery?: string) =>
  buildUrlWithParams(BASE_API_PATH, '/people', { [SearchParams.filterSearch]: searchQuery });
export const apiContacts = (searchQuery?: string) =>
  buildUrlWithParams(BASE_API_PATH, '/contacts', { [SearchParams.filterSearch]: searchQuery });
export const apiAudiences = () => buildUrlWithParams(BASE_API_PATH, '/audiences');
export const apiAges = () => buildUrlWithParams(BASE_API_PATH, '/ages');
export const apiLanguages = (searchQuery?: string) =>
  buildUrlWithParams(BASE_API_PATH, '/languages', { [SearchParams.filterSearch]: searchQuery });
export const apiMaterials = () => buildUrlWithParams(BASE_API_PATH, '/materials');
export const apiFacilitatorMaterials = (id: string) =>
  buildUrlWithParams(BASE_API_PATH, `/facilitator/${id}/materials`);
export const apiMyMaterials = () => buildUrlWithParams(BASE_API_PATH, '/my-materials');
export const apiLessons = (materialId: string) => buildUrlWithParams(BASE_API_PATH, `/lessons/${materialId}`);
export const apiLessonSelectionModes = (materialId: string) =>
  buildUrlWithParams(BASE_API_PATH, `/lessons-selection-mode/${materialId}`);
export const apiGetAvatarLink = () => buildUrlWithParams(BASE_API_PATH, `/profile/avatar`);
export const apiBulkUpload = () => buildUrlWithParams(BASE_API_PATH, `/bulk-upload/`);
export const apiMe = () => buildUrlWithParams(BASE_API_PATH, `/me`);
