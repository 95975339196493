import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { AbortedErrorName, ErrorWithMessageAndResponseStatus, TypeError } from '@/constants/common';
import { useAuth0 } from '@auth0/auth0-react';
import { apiConfidentialCountries, apiGetAvatarLink, apiMe, apiPresignedAvatarUrl } from '@/utils/apiUrls';
import { App } from 'antd';
import { useAuth } from '@/context/AuthContext';
import { MeUserDataResponse } from '@/types/currentUser';
import { Item } from '@/types/common';

export const useCommonFetchers = () => {
  const { t } = useTranslation();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { message } = App.useApp();
  const { setExpiredSessionError } = useAuth();

  const fetcher = async (
    url: string,
    setIsLoading?: Dispatch<SetStateAction<boolean>>,
    options: RequestInit = {},
    errorHandler?: (err: ErrorWithMessageAndResponseStatus) => void,
    statusForErrorHandler?: number,
    tokenCanBeSet = true,
  ): Promise<any> => {
    // Prepare the headers
    const headers = new Headers(options.headers || {});
    headers.set('Accept', `application/json`);

    let shouldShowCustomError = false;

    if (tokenCanBeSet) {
      try {
        const token = isAuthenticated
          ? await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.AUTH0_AUDIENCE,
                scope: 'read:current_user',
              },
            })
          : null;

        if (token) headers.set('Authorization', `Bearer ${token}`);
      } catch (error: any) {
        // auth 0 handles redirect to login page by itself when token is expired
        if (setIsLoading) setIsLoading(false);
        setExpiredSessionError(true);

        throw error;
      }
    }

    const fetchOptions = {
      ...options,
      headers: headers,
    };

    try {
      const response = await fetch(url, fetchOptions as RequestInit);

      if (!response.ok) {
        if (response.status >= 500 && response.status < 600) {
          throw new ErrorWithMessageAndResponseStatus(t('common.messages.somethingWentWrong'), response.status);
        } else {
          const errorText = await response.text();
          let errorMessage;

          try {
            errorMessage = JSON.parse(errorText).error || JSON.parse(errorText).message;
          } catch {
            errorMessage = errorText;
          }

          if (errorHandler && statusForErrorHandler === response.status) {
            shouldShowCustomError = true;
          }

          throw new ErrorWithMessageAndResponseStatus(errorMessage, response.status);
        }
      }

      if (response.status === 204) {
        return null;
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return await response.json();
      } else {
        return response;
      }
    } catch (error: any) {
      if (error.name === AbortedErrorName) return;

      if (setIsLoading) setIsLoading(false);

      if (error.name === TypeError) {
        message.error(t('common.messages.somethingWentWrong'));
      } else if (errorHandler && shouldShowCustomError) {
        errorHandler(error);
      } else if (error instanceof ErrorWithMessageAndResponseStatus) {
        const errorMessage = JSON.parse(JSON.stringify(error)).message;

        message.error(errorMessage);
      } else {
        message.error(error.message);
      }

      throw error;
    }
  };

  const getPresignedAvatarUrl = async (
    userTeamdeskId: string,
    filename: string,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
  ) => {
    return fetcher(apiPresignedAvatarUrl(), setIsLoading, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ file_path: `${userTeamdeskId}/${filename}` }),
    });
  };

  const getAvatar = async (setIsLoading: Dispatch<SetStateAction<boolean>>) => {
    return fetcher(apiGetAvatarLink(), setIsLoading);
  };

  const getMe = async (setIsLoading: Dispatch<SetStateAction<boolean>>): Promise<MeUserDataResponse> => {
    return fetcher(apiMe(), setIsLoading);
  };

  const uploadFileToS3 = async (
    url: string,
    file: Blob,
    setIsLoading?: Dispatch<SetStateAction<boolean>>,
  ): Promise<any> => {
    return fetcher(
      url,
      setIsLoading,
      {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      },
      undefined,
      undefined,
      false,
    );
  };

  const getConfidentialCountries = async (setIsLoading: Dispatch<SetStateAction<boolean>>): Promise<string[]> => {
    const { data }: { data: Item[] } = await fetcher(apiConfidentialCountries(), setIsLoading);

    return data.map((option) => option.name);
  };

  return {
    fetcher,
    getMe,
    getAvatar,
    getPresignedAvatarUrl,
    uploadFileToS3,
    getConfidentialCountries,
  };
};
